/*global _, $, jQuery*/
var MatchHeightHelper = (function ($, _) {
  var MATCHHEIGHT_WRAPPER_CLASS = 'js-match-height';

  /**
   * Makes an update of match height instances if current element is within a
   * suitable .js-match-height parent element.
   *
   * @param HTMLNode element
   *
   * @return void
   */
  var update = function(element, aggressive, selector) {
    var $element = $(element);

    // If any parent (recursive) has .js-match-height, match-height is enabled.
    var
      hasMatchHeight = $element.parents('.' + MATCHHEIGHT_WRAPPER_CLASS).length > 0
        || $element.find('.' + MATCHHEIGHT_WRAPPER_CLASS).length > 0
        || $element.hasClass(MATCHHEIGHT_WRAPPER_CLASS)
    ;

    if (hasMatchHeight) {
      // Try to maintain scroll position.
      $.fn.matchHeight._maintainScroll = true;

      // Update match-height instances.
      if (true === aggressive && 'undefined' !== typeof selector) {
        var $target = $(selector);

        // Destroy & recreate all groups matching selector.
        // https://github.com/liabru/jquery-match-height/issues/60
        _.delay(function () {
          $target.matchHeight({
            remove: true,
          });
          $target.matchHeight({
            byRow: true,
          });
        }, 200);
      } else {
        // Regular update of the existing groups.
        $.fn.matchHeight._update();
      }
    }
  };

  return {update};
}(jQuery, _));

module.exports = MatchHeightHelper;
