import Helper from '../../services/Helper/Helper.js';
import Widget from '../Widget/Widget.js';
import WidgetPart from '../WidgetPart/WidgetPart.js';

/**
 * Select object with a search field.
 *
 * @class Select
 * @constructor
 * @author Franck - Wiztopic
 * @version 1.0.0
 * @requires Helper Widget WidgetPart
 * @example
 *      let select = new Select('#friends');
 *      select.addItem('myValue', 'myLabel');
 *      select.on('unselect', (context)=>{
 *          console.log(context);
 *      });
 *      select.on('search', (context)=>{
 *          console.log(context.searchValues);
 *      });
 *      let item = select.getItemByIndex(5);
 *      select.selectItem(item);
 */
export default class Select extends Widget {
  /**
   * Constructor.
   *
   * @method constructor
   * @param {HTMLElement|HTMLSelectElement|String} target Select target. Can be a css selector or HTML Select element.
   * @since 1.0.0
   * @returns {Select} Returns an instance of Select object.
   */
  // eslint-disable-next-line max-lines-per-function
  constructor(target) {
    super();

    if (Helper.isString(target)) {
      target = document.querySelector(target);
    }
    if (!Helper.isElement(target)) {
      console.error(`WiztopicSelectError: Can not find target '${target}' element from the DOM.`);

      return this;
    }
    if ('SELECT' !== target.tagName) {
      console.error('WiztopicSelectError: Target element must be a HTMLSelectElement.');

      return this;
    }

    /**
     * Select target element.
     *
     * @property target
     * @type {HTMLSelectElement}
     * @since 1.0.0
     */
    this.target = target;
    this.target.style.display = 'none';

    /**
     * Search value storage. Stores keys of each element.
     *
     * @property target
     * @type {String[]}
     * @since 1.0.0
     */
    this.searchValues = [];

    /**
     * Select target options.
     *
     * @property options
     * @type {Object}
     * @since 1.0.0
     */
    this.options = {};

    /**
     * Select items.
     *
     * @property items
     * @type {Object}
     * @since 1.0.0
     */
    this.items = {
      // '567987': {
      //     'Item': '',
      //     'Mark': '',
      //     'Label': '',
      // }
    };

    /**
     * Select container element.
     *
     * @property container
     * @type {HTMLElement}
     * @since 1.0.0
     * @default HTMLDivElement
     */
    this.container = document.createElement('div');
    this.addClass('wiztopic-select-container');
    Helper.insertAfter(this.container, this.target);
    /**
     * Select header widget.
     *
     * @property Header
     * @type {WidgetPart}
     * @since 1.0.0
     */
    this.Header = new WidgetPart(document.createElement('div'));
    this.Header.addClass('wiztopic-select-header');
    this.container.appendChild(this.Header.this);
    /**
     * Select search widget.
     *
     * @property Search
     * @type {WidgetPart}
     * @since 1.0.0
     */
    this.Search = new WidgetPart(document.createElement('input'));
    this.Search.addClass('wiztopic-select-search');
    this.Search.this.setAttribute('placeholder', 'Search items ...');
    this.Search.addEvent('focus', () => {
      this.open();
    });
    this.Search.addEvent('input', (e) => {
      clearTimeout(this.searchTimeOutId);
      this.searchTimeOutId = setTimeout(() => {
        const { value } = e.target;
        this.search(value);
      }, 300);
    });
    this.Header.addChild(this.Search.this);
    /**
     * Select trigger widget.
     *
     * @property Trigger
     * @type {WidgetPart}
     * @since 1.0.0
     */
    this.Trigger = new WidgetPart(document.createElement('div'));
    this.Trigger.addClass('wiztopic-select-trigger');
    this.Trigger.addEvent('click', () => {
      this.isOpen() ? this.close() : this.open();
    });
    this.Header.addChild(this.Trigger.this);
    /**
     * Search wait id.
     *
     * @property searchTimeOutId
     * @type {Number|null}
     * @since 1.0.0
     */
    this.searchTimeOutId = null;

    /**
     * Select body widget.
     *
     * @property Body
     * @type {WidgetPart}
     * @since 1.0.0
     */
    this.Body = new WidgetPart(document.createElement('div'));
    this.Body.addClass('wiztopic-select-body');
    this.container.appendChild(this.Body.this);
    /**
     * Select content wrap widget.
     *
     * @property Wrap
     * @type {WidgetPart}
     * @since 1.0.0
     */
    this.Wrap = new WidgetPart(document.createElement('ul'));
    this.Wrap.addClass('wiztopic-select-body-wrap');
    this.Body.addChild(this.Wrap.this);

    /**
     * Stores the id/key of selected item.
     *
     * @property selectedItem
     * @type {String}
     * @since 1.0.0
     */
    this.selectedItem = null;

    /**
     * Stores the id/key of last selected item.
     *
     * @property lastSelectedItem
     * @type {String}
     * @since 1.0.0
     */
    this.lastSelectedItem = null;

    /**
     * Stores keys of selected items.
     *
     * @property selectedItems
     * @type {Object}
     * @since 1.0.0
     */
    this.selectedItems = {};

    this.clickOutEventCallback  = this.clickOutEventCallback.bind(this);
    this.itemClickEventCallback = this.itemClickEventCallback.bind(this);

    /**
     * Select configuration array.
     *
     * @property config
     * @type {Object}
     * @since 1.0.0
     */
    this.config = {
      /**
       * Select Click out state.
       *
       * @property config.clickOut
       * @type {Boolean}
       * @since 1.0.0
       */
      clickOut: true,
      /**
       * Select events array.
       *
       * @property config.events
       * @type {Object}
       * @since 1.0.0
       */
      events: {
        /**
         * Select close event.
         *
         * @property config.events.close
         * @type {Object}
         * @since 1.0.0
         */
        close: null,
        /**
         * Select open event.
         *
         * @property config.events.open
         * @type {Object}
         * @since 1.0.0
         */
        open: null,
        /**
         * Select search event.
         *
         * @property config.events.search
         * @type {Object}
         * @since 1.0.0
         */
        search: null,
        /**
         * Select select event.
         *
         * @property config.events.select
         * @type {Object}
         * @since 1.0.0
         */
        select: null,
        /**
         * Select unselect event.
         *
         * @property config.events.unselect
         * @type {Object}
         * @since 1.0.0
         */
        unselect: null,
      },
      /**
       * Select multiple state.
       *
       * @property config.multiple
       * @type {Boolean}
       * @since 1.0.0
       */
      multiple: false,
    };

    this.update();
  }

  /**
   * Select click out event callback function.
   *
   * @method clickOutEventCallback
   * @param {Event} e Current Event object
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  clickOutEventCallback(e) {
    if (Helper.isChildOf(e.target, this.container) || (e.target === this.container)) {
      return this;
    }
    if (Helper.isChildOf(e.target, this.target) || (e.target === this.target)) {
      return this;
    }
    if (!this.isOpen()) {
      return this;
    }

    return this.close();
  }

  /**
   * Item click event callback function.
   *
   * @method itemClickEventCallback
   * @param {Event} e Current Event object
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  itemClickEventCallback(e) {
    const id = e.currentTarget.id.replace('item-', '');
    this.toggleItem(this.items[id] ? this.items[id].Item : null);

    return this;
  }

  /**
   * Adds item to Select object.
   *
   * @method addItem
   * @param {HTMLOptionElement|String} value Item value.
   * @param {String} label Item label.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  addItem(value, label) {
    let option = null;
    if (Helper.isElement(value)) {
      option = value;
      value  = option.value || null;
      label  = option.innerHTML || '';
    }
    if (!option) {
      option = document.createElement('option');
    }
    const id         = `${Helper.generateUniqueId()}`;
    option.id        = `option-${id}`;
    option.value     = value;
    option.innerHTML = label;
    this.options[id] = option;
    if (option.parentNode !== this.target) {
      this.target.appendChild(option);
    }

    const Item = new WidgetPart(document.createElement('li'));
    Item.addClass('wiztopic-select-item');
    Item.this.id = `item-${id}`;
    Item.addEvent('click', this.itemClickEventCallback);
    this.items[id] = { Item };
    // Todo : export value
    this.Wrap.addChild(Item.this);

    // -- value
    this.items[id].value = value;

    // -- mark
    const Mark = new WidgetPart(document.createElement('span'));
    Mark.addClass('wiztopic-select-item-mark');
    Mark.this.id = `item-${id}-mark`;
    Item.addChild(Mark.this);
    this.items[id].Mark = Mark;
    // -- label
    const Label = new WidgetPart(document.createElement('span'));
    Label.addClass('wiztopic-select-item-label');
    Label.this.id        = `item-${id}-label`;
    Label.this.innerHTML = label;
    Item.addChild(Label.this);
    this.items[id].Label = Label;

    return this;
  }

  /**
   * Removes item from Select object.
   *
   * @method removeItem
   * @param {WidgetPart} item Use getItemByValue or getItemByIndex method to get an item.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  removeItem(item) {
    const id = this.getItemId(item);
    if (!id) {
      return this;
    }
    // Remove item
    const { Item } = this.items[id];
    Item.remove();
    delete this.items[id];
    // Remove option
    const option = this.options[id];
    option.parentNode.removeChild(option);
    delete this.options[id];

    return this;
  }

  /**
   * Gets Select item id.
   *
   * @method getItemId
   * @param {WidgetPart} item Use getItemByValue or getItemByIndex method to get an item.
   * @since 1.0.0
   * @returns {String|null} Returns item id or null if id not found.
   */
  // eslint-disable-next-line class-methods-use-this
  getItemId(item) {
    try {
      return item.this.id.replace('item-', '');
    } catch (e) {
      return null;
    }
  }

  /**
   * Gets Select item using its id.
   *
   * @method getItemById
   * @param {String} id Id of item.
   * @since 1.0.0
   * @returns {WidgetPart} Returns WidgetPart of item.
   */
  getItemById(id) {
    try {
      return this.items[id].Item;
    } catch (e) {
      return null;
    }
  }

  /**
   * Gets Select item using its value.
   *
   * @method getItemByValue
   * @param {String} value Value of item.
   * @since 1.0.0
   * @returns {WidgetPart} Returns WidgetPart of item.
   */
  getItemByValue(value) {
    const item = null;
    const keys = Object.keys(this.items);
    for (let k = 0; k < keys.length; k += 1) {
      if (this.items[keys[k]].value === value) {
        return this.items[keys[k]].Item;
      }
    }

    return item;
  }

  /**
   * Gets Select item using its index.
   *
   * @method getItemByValue
   * @param {Number} index Index of item.
   * @since 1.0.0
   * @returns {WidgetPart} Returns WidgetPart of item.
   */
  getItemByIndex(index) {
    const option = this.target.options[index];
    try {
      const id = option.id.replace('option-', '');

      return this.getItemById(id);
    } catch (e) {
      return null;
    }
  }

  /**
   * Selects item.
   *
   * @method selectItem
   * @param {WidgetPart} item Use getItemByValue or getItemByIndex method to get an item.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  selectItem(item) {
    const id = this.getItemId(item);
    if (!id) {
      return this;
    }
    if (id === this.selectedItem) {
      return this;
    }
    const option = this.options[id];
    const Item   = this.items[id] ? this.items[id].Item : null;
    if (!Item || !option) {
      return this;
    }
    if (!this.config.multiple && this.selectedItem === id) {
      return this;
    }
    if (this.items[this.selectedItem] && !this.config.multiple) {
      this.options[this.selectedItem].selected = false;
      this.items[this.selectedItem].Item.removeClass('wiztopic-select-item-selected');
    }
    option.selected = true;
    Item.addClass('wiztopic-select-item-selected');
    this.selectedItem = id;
    if (!this.isOpen()) {
      this.Search.this.value = this.getSelectedLabels().join(', ');
    }
    if (this.config.multiple) {
      this.selectedItems[id] = true;
    }
    if (this.config.events.select) {
      this.config.events.select.apply(null, [this]);
    }

    return this;
  }

  /**
   * Un-selects item.
   *
   * @method unSelectItem
   * @param {WidgetPart} item Use getItemByValue or getItemByIndex method to get an item.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  unSelectItem(item) {
    const id = this.getItemId(item);
    if (!id) {
      return this;
    }
    const option = this.options[id];
    const Item   = this.items[id] ? this.items[id].Item : null;
    if (!Item || !option) {
      return this;
    }
    if (!this.config.multiple && this.selectedItem === id) {
      return this;
    }
    option.selected = false;
    Item.removeClass('wiztopic-select-item-selected');
    this.selectedItem     = null;
    this.lastSelectedItem = id;
    if (!this.isOpen()) {
      this.Search.this.value = this.getSelectedLabels().join(', ');
    }
    if (this.config.multiple) {
      delete this.selectedItems[id];
    }
    if (this.config.events.unselect) {
      this.config.events.unselect.apply(null, [this]);
    }

    return this;
  }

  /**
   * Un-selects all items.
   *
   * @method unSelectAll
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  unSelectAll() {
    Object.keys(this.items).map((id) => this.unSelectItem(this.items[id].Item));

    return this;
  }

  /**
   * Toggles item selection.
   *
   * @method toggleItem
   * @param {WidgetPart} item Use getItemByValue or getItemByIndex method to get an item.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  toggleItem(item) {
    const id = this.getItemId(item);
    if (!id) {
      return this;
    }
    const option = this.options[id];
    if (!option) {
      return this;
    }
    this[option.selected ? 'unSelectItem' : 'selectItem'](item);

    return this;
  }

  /**
   * Opens Select.
   *
   * @method open
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  open() {
    this.Search.this.value = '';
    this.addClass('wiztopic-select-is-open');
    const keys = Object.keys(this.items);
    keys.map((key) => this.items[key].Item.removeClass('wiztopic-select-item-not-found'));
    if (this.config.events.open) {
      this.config.events.open.apply(null, [this]);
    }

    return this;
  }

  /**
   * Closes Select.
   *
   * @method close
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  close() {
    this.Search.this.value = this.getSelectedLabels().join(', ');
    this.removeClass('wiztopic-select-is-open');
    if (this.config.events.close) {
      this.config.events.close.apply(null, [this]);
    }

    return this;
  }

  /**
   * Checks if Select is open.
   *
   * @method isOpen
   * @since 1.0.0
   * @returns {Boolean} Returns true if Select is open and false otherwise.
   */
  isOpen() {
    return this.container.classList.contains('wiztopic-select-is-open');
  }

  /**
   * Sets if Select will close on click outside of the Select.
   *
   * @method clickOut
   * @param {Boolean} close If true, Select will be close.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  clickOut(close = true) {
    this.config.clickOut = close;
    Helper.removeEvent(document, 'mouseup', this.clickOutEventCallback);
    if (this.config.clickOut) {
      Helper.addEvent(document, 'mouseup', this.clickOutEventCallback);
    }

    return this;
  }

  /**
   * Sets if Select will have multiple selection.
   *
   * @method multiple
   * @param {Boolean} multiple If true, Select will have multiple selection.
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  multiple(multiple = true) {
    this.config.multiple = multiple;
    this.target.removeAttribute('multiple');
    if (multiple) {
      this.target.setAttribute('multiple', 'multiple');
    }
    this.removeClass('wiztopic-select-single-type');
    this.removeClass('wiztopic-select-multiple-type');
    this.addClass(multiple ? 'wiztopic-select-multiple-type' : 'wiztopic-select-single-type');

    return this;
  }

  /**
   * Gets selected values.
   *
   * @method getSelected
   * @since 1.0.0
   * @returns {String[]} Returns array containing the values.
   */
  getSelected() {
    const result = [];
    let items    = [this.selectedItem];
    if (this.config.multiple) {
      items = Object.keys(this.selectedItems);
    }
    items.map((item) => result.push(this.items[item].value));

    return result;
  }

  /**
   * Gets selected labels.
   *
   * @method getSelectedLabels
   * @since 1.0.0
   * @returns {String[]} Returns array containing the labels.
   */
  getSelectedLabels() {
    const result = [];
    let keys     = null;
    if (this.config.multiple) {
      keys = Object.keys(this.selectedItems);
    } else {
      keys = [this.selectedItem];
    }
    keys.map((key) => {
      const label = this.items[key].Item.this.querySelector(`#item-${key}-label`);
      result.push(label.textContent);

      return key;
    });

    return result;
  }

  /**
   * Updates Select object.
   *
   * @method update
   * @since 1.0.0
   * @returns {Select} Returns the current Select object.
   */
  update() {
    this.multiple(this.target.hasAttribute('multiple'));
    const options = Helper.convertToArray(this.target.options);
    options.map((option) => {
      this.addItem(option);
      const id = option.id.replace('option-', '');
      if (option.hasAttribute('selected')) {
        this.selectItem(this.items[id].Item);
      }

      if (this.config.multiple && !option.hasAttribute('selected')) {
        this.unSelectItem(this.items[id].Item);
      }

      return option;
    });
    if (!this.config.multiple) {
      const id   = this.target.options[this.target.selectedIndex].id.replace('option-', '');
      const item = this.items[id];
      this.selectItem(item ? item.Item : null);
    }
    this.clickOut(this.config.clickOut);
    this.Search.this.value = this.getSelectedLabels().join(', ');

    return this;
  }

  /**
   * Search values using value or label.
   *
   * @method search
   * @param {String} value Value or label to search.
   * @since 1.0.0
   * @returns {String[]} Returns keys of each element. Found values are stored in the searchValues property
   */
  search(value) {
    value             = Helper.slugify(value);
    this.searchValues = [];
    const keys        = Object.keys(this.items);
    keys.map((key) => {
      const itemValue = this.items[key].value;
      const itemLabel = this.items[key].Label.this.textContent;
      if (
        -1 < Helper.slugify(itemValue.toLowerCase()).indexOf(value)
        || -1 < Helper.slugify(itemLabel.toLowerCase()).indexOf(value)
      ) {
        this.items[key].Item.removeClass('wiztopic-select-item-not-found');
        this.searchValues.push(key);
      } else {
        this.items[key].Item.addClass('wiztopic-select-item-not-found');
      }

      return key;
    });
    if (this.config.events.search) {
      this.config.events.search.apply(null, [this]);
    }

    return this.searchValues;
  }
}
