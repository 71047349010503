$('[data-content-selector=".offie-collapse"]').on('init.jq.offie', function(event) {
  if (event.offie.isOpened()) {
    let $target = event.offie.getTarget();
    $target.height($target[0].scrollHeight);
  }
});

$('[data-content-selector=".offie-collapse"]').on('showing.jq.offie', function(event) {
  let $target = event.offie.getTarget();
  $target.height($target[0].scrollHeight);
});

$('[data-content-selector=".offie-collapse"]').on('shown.jq.offie', function(event) {
  event.offie.getTarget().height('auto');
});

$('[data-content-selector=".offie-collapse"]').on('hiding.jq.offie', function(event) {
  let $target = event.offie.getTarget();
  $target.height($target[0].scrollHeight).height(0);
});
