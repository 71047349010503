let
  $this = $('.js-sharing-affix'),
  smoothOffsetBottom,
  smoothOffsetTop,
  $bottomReference,
  initialRight,
  initialTop,
  resetPositionRight
;

let computeAffixRightPosition = function() {
  return $this.offset().right;
};

let onResetAffixRightPosition = function(event) {
  $this.css({
    'right': initialRight,
    'top': initialTop,
  });
};

let initAffix = function() {
  if (!$this.length) {
    return;
  }

  smoothOffsetBottom = $this.data('refineOffsetBottom') ? $this.data('refineOffsetBottom'): 0,
  smoothOffsetTop = $this.data('refineOffsetTop') ? $this.data('refineOffsetTop'): 0,
  $bottomReference = $($this.data('bottomReference')),
  initialRight = parseInt($this.css('right')),
  initialTop = parseInt($this.css('top')),
  resetPositionRight = ($(window).width() - ($this.offset().left + $this.outerWidth()));

  $this.affix({
    'offset': {
      'bottom': function () {
        if (!$bottomReference.length) {
          return null;
        }

        let nextOffsetTop = $bottomReference.offset().top + $bottomReference.outerHeight();
        return this.bottom = Math.max($(document).height(), $(window).height()) - (nextOffsetTop - smoothOffsetBottom);
      },
      'top': $this.offset().top - smoothOffsetTop,
    },
  });

  $this.on('affixed.bs.affix', function(e) {
    $this.css({
      'right': resetPositionRight,
      'top': smoothOffsetTop + 'px',
    });
  });
  $this.on('affixed-top.bs.affix', onResetAffixRightPosition);
  $this.on('affixed-bottom.bs.affix', onResetAffixRightPosition);
};

initAffix();
