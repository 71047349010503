/**
 * Various useful functions.
 *
 * @class Helper
 * @static
 * @author Franck
 * @examples
 *      // Returns true
 *      Heller.isElement(document.createElement('div'));
 *      // Returns true
 *      Heller.isObject({});
 *      // Return true
 *      Helper.isEmpty({});
 * @version 1.0.0
 */
export default class Helper {
  /**
     * Gets type of any object.
     *
     * @method getType
     * @param object Object we want to know the type.
     * @return {String} Returns the type of object.
     */
  static getType(object) {
    if (null === object) {
      return null;
    }
    let t = (typeof object);
    if ('object' === t) {
      const objectConstructor = String(object.constructor);
      if (/^(?:function|object) ([a-z0-9-]+)\(?/i.test(objectConstructor)) {
        t = RegExp.$1;
        if (/^html[a-z]*element$/i.test(t)) {
          t = 'Element';
        }
      } else {
        t = undefined;
      }
    }

    return t;
  }

  /**
     * Checks if an object is a string.
     *
     * @method isString
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a string and false otherwise.
     */
  static isString(object) {
    return 'string' === this.getType(object);
  }

  /**
     * Checks if an object is a number.
     *
     * @method isNumber
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a number and false otherwise.
     */
  static isNumber(object) {
    return 'number' === this.getType(object);
  }

  /**
     * Checks if an object is a array.
     *
     * @method isArray
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a array and false otherwise.
     */
  static isArray(object) {
    return 'Array' === this.getType(object);
  }

  /**
     * Checks if an object is a object.
     *
     * @method isObject
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a object and false otherwise.
     */
  static isObject(object) {
    return 'Object' === this.getType(object);
  }

  /**
     * Checks if an object is a boolean.
     *
     * @method isBoolean
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a boolean and false otherwise.
     */
  static isBoolean(object) {
    return 'boolean' === this.getType(object);
  }

  /**
     * Checks if an object is a DOM element.
     *
     * @method isElement
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a DOM element and false otherwise.
     */
  static isElement(object) {
    return 'Element' === this.getType(object);
  }

  /**
     * Checks if an object is a function.
     *
     * @method isFunction
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a function and false otherwise.
     */
  static isFunction(object) {
    return 'function' === this.getType(object);
  }

  /**
     * Checks if an object is a function.
     *
     * @method isCallback
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a function and false otherwise.
     */
  static isCallback(object) {
    return this.isFunction(object);
  }

  /**
     * Checks if an object is a FormData.
     *
     * @method isFormData
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is a FormData and false otherwise.
     */
  static isFormData(object) {
    return 'FormData' === this.getType(object);
  }

  /**
     * Checks if an object is empty.
     *
     * @method isEmpty
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true for
     *    empty array, string, object, false, undefined, 0, null, NaN
     *    and false otherwise.
     */
  static isEmpty(object) {
    if (!object) {
      return true;
    }
    const objectKeys = Object.keys(object);
    for (let k = 0; k < objectKeys.length; k += 1) {
      if (Object.prototype.hasOwnProperty.call(object, objectKeys[k])) {
        return false;
      }
    }

    return !(true === object || this.isNumber(object));
  }

  /**
     * Checks if an object is null.
     *
     * @method isNull
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is null and false otherwise.
     */
  static isNull(object) {
    return null === object;
  }

  /**
     * Checks if an object is false.
     *
     * @method isFalse
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is false and false otherwise.
     */
  static isFalse(object) {
    return false === object;
  }

  /**
     * Checks if an object is true.
     *
     * @method isTrue
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is true and false otherwise.
     */
  static isTrue(object) {
    return true === object;
  }

  /**
     * Checks if a string is blank.
     *
     * @method isBlank
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the string is blank and false otherwise.
     */
  static isBlank(object) {
    return this.isString(object) && '' === object.trim();
  }

  /**
     * Checks if an object is regular expression.
     *
     * @method isRegExp
     * @param object Object we want to know the type.
     * @return {Boolean} Returns true if the object is regular expression and false otherwise.
     */
  static isRegExp(object) {
    return 'RegExp' === this.getType(object);
  }

  /**
     * Converts an object to array.
     *
     * @method convertToArray
     * @param object Object to convert.
     * @return {Array} Returns the resulting array.
     */
  static convertToArray(object) {
    if (this.isObject(object)) {
      return Object.keys(object);
    }

    return [].slice.call(object);
  }

  /**
     * @todo : to refactor (add container element)
     * Inserts one element after another.
     *
     * @method insertAfter
     * @param {HTMLElement} element Element to insert.
     * @param {HTMLElement} afterElement Insert an element after this one.
     * @return {boolean} Returns true if everything went well and false otherwise.
     */
  static insertAfter(element, afterElement) {
    const parent = afterElement.parentNode;
    if (!parent) {
      return false;
    }
    const next = afterElement.nextElementSibling;
    if (next) {
      parent.insertBefore(element, next);
    } else {
      parent.appendChild(element);
    }
    
    return true;
  }

  /**
     * Checks if an element is child of another.
     *
     * @method isChildOf
     * @param {Node||HTMLElement} child Element to check.
     * @param {HTMLElement} parent Container element.
     * @return {boolean}
     */
  static isChildOf(child, parent) {
    if (!this.isElement(child) || !this.isElement(parent)) {
      return false;
    }

    while (child) {
      child = child.parentNode;
      if (child === parent) {
        return true;
      }
    }

    return false;
  }

  /**
     * Converts the first character of string to lower case.
     *
     * @method lowerFirst
     * @param {String} text The string to convert.
     * @return {String} Returns the converted string.
     */
  static lowerFirst(text) {
    return (text.slice(0, 1)).toLowerCase() + text.slice(1);
  }

  /**
     * Converts the first character of string to upper case.
     *
     * @method upperFirst
     * @param {String} text The string to convert.
     * @return {String} Returns the converted string.
     */
  static upperFirst(text) {
    return (text.slice(0, 1)).toUpperCase() + text.slice(1);
  }

  /**
     * Converts text to camelCase.
     *
     * @method camelCase
     * @param {String} text The string to convert.
     * @return {String} Returns the converted string.
     */
  static camelCase(text) {
    return text.replace(/\s(.)/g, ($1) => $1.toUpperCase())
      .replace(/-(.)/g, ($1) => $1.toUpperCase())
      .replace(/\s/g, '').replace(/-/g, '')
      .replace(/^(.)/, ($1) => $1.toLowerCase());
  }

  /**
     * Converts text to slug.
     *
     * @method slugify
     * @param {String} text The string to convert.
     * @return {String} Returns the converted string.
     */
  static slugify(text) {
    return text.toLowerCase()
      .replace(/[\u00C0-\u00C5]/ig, 'a')
      .replace(/[\u00C8-\u00CB]/ig, 'e')
      .replace(/[\u00CC-\u00CF]/ig, 'i')
      .replace(/[\u00D2-\u00D6]/ig, 'o')
      .replace(/[\u00D9-\u00DC]/ig, 'u')
      .replace(/[\u00D1]/ig, 'n')
      .replace(/[^a-z0-9 ]+/gi, '')
      .trim()
      .replace(/ /g, '-')
      .replace(/[-]{2}/g, '')
      .replace(/[^a-z\- ]*/gi, '');
  }

  /**
     * Truncates a string.
     *
     * @method ellipsis
     * @param {String} text The string to truncate.
     * @param {Number} count Number of char.
     * @return {String} Returns the truncated string.
     */
  static ellipsis(text, count = 12) {
    return (text.length > count) ? `${text.substring(0, count)}...` : text;
  }

  /**
     * Checks if an object has a property.
     *
     * @method hasProperty
     * @param {Object} object Object.
     * @param {String} property Property to check.
     * @return {Boolean} Returns true if object has property and false otherwise.
     */
  static hasProperty(object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
  }

  /**
     * Adds event to element.
     *
     * @method addEvent
     * @param {HTMLElement|Document} element Target element.
     * @param {String} event Event name.
     * @param {Function} callback Event callback.
     * @return {void}
     */
  static addEvent(element, event, callback) {
    if (element.addEventListener) {
      element.addEventListener(event, callback, false);
    } else {
      element.attachEvent(`on${event}`, callback);
    }
  }

  /**
     * Removes event.
     *
     * @method removeEvent
     * @param {HTMLElement|Document} element Target element.
     * @param {String} event Event name.
     * @param {Function} callback Event callback.
     * @return {void}
     */
  static removeEvent(element, event, callback) {
    if (element.removeEventListener) {
      element.removeEventListener(event, callback, false);
    } else {
      element.detachEvent(`on${event}`, callback);
    }
  }

  /**
     * Generates unique id.
     *
     * @method generateUniqueId
     * @param {Number} count Length of id.
     * @return {Number} Returns generated id.
     */
  static generateUniqueId(count = 6) {
    return Math.floor(Math.random() * (10 ** count));
  }

  /**
     * Removes value from array using its index.
     *
     * @method removeFromArrayByIndex
     * @param {Array} values Values.
     * @param {Number} index Index of value to remove.
     * @return {Array} Returns array without value.
     */
  static removeFromArrayByIndex(values, index) {
    if (-1 < index) {
      values.splice(index, 1);
    }
    
    return values;
  }

  /**
     * Generates random integer.
     *
     * @method randomInt
     * @param {Number} max Value will be between 0 and max.
     * @return {Number} Returns random integer.
     */
  static randomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  /**
     * Generate random hexadecimal color code.
     * @method randomColor
     * @return {String} Returns a random hexadecimal color code.
     */
  static randomColor() {
    return `#${
      ('0123456789abcdef'.split('').map(
        (v, i, a) => (5 < i ? null : a[Math.floor(Math.random() * 16)]),
      ).join('')
      ).toLowerCase()
    }`;
  }

  /**
     * Get array object value by key.
     *
     * @method getByKey
     * @param {Object} object Array object.
     * @param {String} key Key.
     */
  static getByKey(object, key) {
    const keys = key.split('.');

    for (let i = 0; i < keys.length; i += 1) {
      key = keys[i];
      if (!object || !Helper.hasProperty(object, key)) {
        return null;
      }
      object = object[key];
    }

    return object;
  }

  /**
     * Sort array object value by key.
     *
     * @method sortByObject
     * @param {Object} object Array object.
     * @param {String} key Key.
     */
  static sortByObject(object, key) {
    return object.sort((a, b) => {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      
      return 0;
    });
  }
}
