/*global _, $, jQuery, Modernizr*/
const svg4everybody = require('svg4everybody');

let xhrOnOpen = XMLHttpRequest.prototype.open;

function onStateChange(event) {
  // fires on every readystatechange ever
  // use `this` to determine which XHR object fired the change event.
  if (this.responseURL && '.svg' === this.responseURL.slice(-4)) {
    if ('function' !== typeof Event) {
      if ('function' !== document.createEvent) {
        // IE8 => not supported for now.
        return;
      }

      // Fallback case for IE, not supporting new Event.
      event = document.createEvent('Event');
      event.initEvent('done.svg4everybody', true, true);

      return document.dispatchEvent(event);
    }

    event = new Event('done.svg4everybody');
    document.body.dispatchEvent(event);
  }
}

XMLHttpRequest.prototype.open = function() {
  if (Modernizr.eventlistener) {
    this.addEventListener('load', onStateChange);
  } else if ('function' === typeof window.attachEvent) {
    // Fallback case for IE, not supporting addEventListener.
    this.attachEvent('load', onStateChange);
  }

  xhrOnOpen.apply(this, arguments);
};

$(function () {
  svg4everybody({
    attributeName: 'data-href',
    polyfill: true,
  });
});
