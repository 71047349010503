/*global _, $, jQuery, MatchHeightHelper*/
import Helper from './services/Helper/Helper.js';
import Select from './widgets/Select/Select.js';

(function($) {
  $(function() {
    var
      $body                  = $('body'),
      nativeCookiesActivated = $body.data('cookies-activated'),
      cookieAcceptLinkText   = $body.data('cookiesLinkAcceptText'),
      cookieLinkText         = $body.data('cookiesLinkText'),
      cookieMessage          = $body.data('cookiesMessage'),
      cookiePolicyUrl        = $body.data('cookiesPolicyUrl'),
      cookiePosition         = 'bottom' === $body.data('cookiesPosition'),
      country                = $body.data('country') ? $body.data('country') : 'fr'
    ;

    if(nativeCookiesActivated) {
      $.cookieCuttr({
        cookieAnalytics: false,
        cookieMessage: cookieMessage + ('fr' === country ? '&nbsp;<a class="cc-cookie-more" target="_blank" href="{{cookiePolicyLink}}">' + cookieLinkText + '</a>' : ''),
        cookieAcceptButtonText: cookieAcceptLinkText,
        cookiePolicyLink: cookiePolicyUrl,
        cookieNotificationLocationBottom: cookiePosition
      });
    }

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    // Init Fancybox
    require('fancybox/dist/js/jquery.fancybox.cjs.js')($);
    $('.fancybox').fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('undefined' !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        'highlight': function(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        'unhighlight': function(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        'errorElement': 'span',
        'errorClass': 'help-block',
        'errorPlacement': function(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      $.validator.addMethod('pattern', function(value, element, regexp)  {
        var pattern = new RegExp(regexp);

        return this.optional(element) || pattern.test(value);
      });

      // $.validator.addMethod('alphanumeric', function(value, element) {
      //   var regexp = new RegExp(/^[\u4e00-\u9fff_A-Za-zа-яёЁÀ-Ÿ][\u4e00-\u9fff_a-zа-яёЁÀ-Ÿ0-9\-\s]+$/i);

      //   return regexp.test(value);
      // }, 'Letters, numbers, spaces or dashes only');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
        $(e).validate({
          'ignore': [],
          'rules': {
            'front_message_type[email]': {
              'email': false,
              'cemail': true
            },
            'front_newsletter_type[email]': {
              'email': false,
              'cemail': true
            },
            'hiddenRecaptcha': {
              required: function() {
                return grecaptcha.getResponse() == '' ? true : false;
              }
            }
          }
        });
      });

      $('.js-press-review-form').validate({
        'rules': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: "^[0-9]{4}$",
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: "^[a-zA-Z]{3}[0-9]{3}$",
          },
        },
        'messages': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: $('[name="front_press_review_type[pressReviewFields][costCenter]"]').data('msg-error')
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: $('[name="front_press_review_type[pressReviewFields][entityCode]"]').data('msg-error')
          },
        }
      })

      $('.max_length_rule').each(function() {
        $(this).rules('add', {
          maxlength: 100,
          alphanumeric: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.email_rule').each(function() {
        $(this).rules('add', {
          email: false,
          cemail: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.js-press-review-form input').blur(function() {
        var isValid = $(this).attr('aria-invalid');
        var isValid = isValid === 'true';

        $('#front_press_review_type_submit').attr('disabled', isValid);
      })
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      MatchHeightHelper.update($container[0], true, '.js-match-height .card');
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      var buckle = $(event.target).closest('.js-buckle--folder').data('buckle');

      // Fetch content of the folder.
      buckle.fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      // Update the grid.
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      'format': 'yyyy-mm-dd',
      'startView': 'year',
      'minView': 'month',
      'fontAwesome': true,
      'autoclose': true
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    Helper
      .convertToArray(document.querySelectorAll('select'))
      .map((select) => new Select(select).open())
    ;

    // Press Review
    var pressReviewLink = $('.section--press_review-jumbotron .section-btn').attr('href');

    $('.section--press-review-index .section-btn').attr('href', pressReviewLink);

    $('.btn-toggle-box').on('click', function() {
      $('.icon-search, .icon-arr').toggleClass('active');
      $('.form--clientsearch').toggleClass('active');
    });

    // Footer contact block
    $('.section--contact-footer .card-btn').on('click', function() {
      $(this).toggleClass('rotated');
      $('.section--contact-footer .card-text').slideToggle();
    });

    $('.header-language .nav-item').on('click', function() {
      $('.header-language .nav-item').not($(this)).removeClass('active-lang');
      $(this).addClass('active-lang');
    });

    // Newsletter Page Contact Block
    const ctaEmail = document.getElementById('ctaEmail'),
          ctaPhone = document.getElementById('ctaPhone'),
          email    = document.getElementById('contactEmail'),
          phone    = document.getElementById('contactPhone')
    ;

    if (ctaEmail) {
      ctaEmail.addEventListener('click', function () {
        ctaEmail.remove();
        email.style.visibility = 'visible';
      });
    }

    if (ctaPhone) {
      ctaPhone.addEventListener('click', function () {
        ctaPhone.remove();
        phone.style.visibility = 'visible';
      });
    }

    $('.header-burger').on('click', function() {
      $('body').css('overflow', 'hidden');
      $('.block-menu-detail').addClass('active');
    });

    $('.close-menu-btn').on('click', function() {
      $('body').css('overflow', 'inherit');
      $('.block-menu-detail').removeClass('active');
    });

    $('.js-icon-share').on('click', function() {
      $(this).addClass('hidden');
      $(this).next().addClass('active');
      $('.overlay-share').addClass('active');
    });

    $('.overlay-share, .btn-close-share').on('click', function() {
      $('.overlay-share').removeClass('active');
      $(this).removeClass('active');
      $('.overlay-share').next().children('.list--share').removeClass('active');
      $('.js-icon-share').removeClass('hidden');
    });

    // Didomi - Display Banner
    let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
    if (didomiBtn != null) {
      didomiBtn.addEventListener('click', function() {
        window.Didomi?.preferences.show();
      });
    }
  });
}(jQuery));
